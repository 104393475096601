import React, { useEffect, useState } from 'react'
import PageHeading from './PageHeading';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function CompareBarGraph(props) {

    let [datas, setData] = useState([])
    let [option, setOption] = useState([])

    let onChange = (e) => {
        const radioButtons = document.querySelectorAll('div[name="radio"]');
        const selectedValue = e.target.id;
        Array.from(radioButtons).map(rb => {
            document.getElementById(rb.id).style.backgroundColor = "#3E40951A"
        });
        document.getElementById(selectedValue).style.backgroundColor = "#3E4095"

    }

    // let data = {
    //     labels: props.datalabel,
    //     // datasets: [...props.dataArray],
    //     datasets: [
    //         {
    //             label: 'Sample Data',
    //             data: [65, 59, 80, 81, 56, 55, 40],
    //             backgroundColor: '#E3E4ED',
    //             borderColor: '#E3E4ED',
    //             borderWidth: 1,
    //             barThickness: 10
    //         },
    //     ]

    // }

    ChartJS.defaults.scale.grid.display = false;
    ChartJS.defaults.scale.border.display = false;

    let barPattern = {
        id: "barPattern",
        beforeDatasetDraw(chart, args, plugingOptions) {
            const { ctx, chartArea: { top, bottom, height }, scales: { x, y } } = chart
            ctx.save();
            const width = chart.getDatasetMeta(0).data[0].width;
            chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
                // ctx.fillStyle =  'rgba(227, 228, 237, 1)'
                ctx.fillStyle = 'rgba(227, 228, 237, 1)';
                ctx.fillRect((x.getPixelForValue(index) - width / 2), top, width * 1.07, height - 0.5)
            })
        }

    }

    useEffect(() => {
        setData({
            labels: props.datalabel,
            datasets: props.dataArray,
            // datasets: [
            //     {
            //         label: 'Sample Data',
            //         data: [65, 59, 80, 81, 56, 55, 40],
            //         backgroundColor: '#E3E4ED',
            //         borderColor: '#E3E4ED',
            //         borderWidth: 1,
            //         barThickness: 10
            //     },
            // ]

        })
        setOption({
            responsive: true,
           
            scales: {
                x: [{
                    labels:"vcdfvdf",
                    type: 'category',

                    scaleLabel: {
                        display: true,
                        labelString: props.yLabel
                    },

                }],
                y: [{
                    ticks: {

                        beginAtZero: true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: props.xLabel
                    },

                }],
            },
        })
    }, [props])

    return (
        <div className='container-fluid landformconatiner p-4' style={{height:"705px"}}>
            <div className='d-flex flex-column gap-2' >
                <div className='d-flex flex-row justify-content-between'>
                    <PageHeading pagename={props.title} />
                    <div className='d-flex flex-row gap-4'>
                        {/* <div className='d-flex flex-row gap-4 pt-2'>
                            <div className='d-flex flex-row'>
                                <div id="office" onClick={onChange} value="office" name='radio' style={{ width: "20px", height: "20px", borderRadius: "100px", backgroundColor: "#3E40951A" }}>

                                </div>
                                <span for="office" className='ps-1' style={{ fontWeight: "400", fontSize: "0.9rem", lineHeight: "21px", color: "#737791" }}>Office</span>
                            </div>
                            <div className='d-flex flex-row'>
                                <div id="project" onClick={onChange} value="project" name='radio' style={{ width: "20px", height: "20px", borderRadius: "100px", backgroundColor: "#3E40951A" }}>

                                </div>
                                <span className='ps-1' style={{ fontWeight: "400", fontSize: "0.9rem", lineHeight: "21px", color: "#737791" }}>Project</span>
                            </div>
                        </div> */}
                        <select className='form-input-box pe-5' style={{ height: "35px", paddingRight: "0px", paddingLeft: "0px", backgroundColor: "white", border: "1px solid #EDEDED", borderRadius: "5px" }} onChange={(e)=>{props.setDaysId(e.target.value)}}>
                            <option value={1}>
                                Week
                            </option>
                            <option value={2}>
                                Month
                            </option>
                            <option value={3}>
                                Year
                            </option>

                        </select>
                    </div>

                </div>
                <div className='pt-2' style={{ maxHeight: "368px", overflowY: "auto" }}>

                    {
                        Object.keys(datas).length > 0 ?

                            props.dataArray.length === 1 ?
                                <Bar data={datas} options={option} plugins={[barPattern]} />
                                :
                                <Bar data={datas} options={option} />
                            : ""
                    }
                </div>
            </div>
        </div>
    )
}
