import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Login/Login'
import ForgotPassowrd from '../pages/Login/ForgotPassowrd'
import ResetPassword from '../pages/Login/ResetPassword'
import SignUp from '../pages/Login/SignUp'
import AllProject from '../pages/Project/AllProject'
import LandLayout from '../layouts/LandLayout'
import ProjectDashboard from '../pages/Project/ProjectDashboard.js/ProjectDashboard'
import CityMaster from '../pages/Master/CityMaster'
import MainLayout from '../layouts/MainLayout'
import Authentication from './Authentication'
import PageNotFound from '../pages/PageNotFound'
import ExpensesSummery from '../pages/Expenses/ExpensesSummery/ExpensesSummery'
import SecondLandOwnerShow from '../pages/AddLand/ShowLand/SecondLandOwnerShow'
import IndividualInvestor from '../pages/Investors/Individual/IndividualInvestor'
import ShowSingleIndividualInvestorTransactions from '../pages/Investors/Individual/ShowSingleIndividualInvestorTransactions'
import ViewProfileIndividualInvestor from '../pages/Investors/Individual/ViewProfileIndividualInvestor'
import InsertIndividualInvestor from '../pages/Investors/Individual/InsertIndividualInvestor'
import CompanyInvestor from '../pages/Investors/Company/CompanyInvestor'
import AddCompanyDetails from '../pages/Investors/Company/AddCompanyDetails'
import ShowSingleIndividualCompanyTransactions from '../pages/Investors/Company/ShowSingleIndividualCompanyTransactions'
import ViewProfileIndividualCompany from '../pages/Investors/Company/ViewProfileIndividualCompany'
import ExpenseMaster from '../pages/Master/ExpenseMaster'
import PaymentMaster from '../pages/Master/PaymentMaster'
import InvestorList from '../pages/Investors/InvestorList'
import AccountDashboardIndex from '../pages/Accounting/AccountDashboard/AccountDashboardIndex'
import AccountDashboardAddIndex from '../pages/Accounting/AccountDashboardAdd/AccountDashboardAddIndex'
import SalesOverviewIndex from '../pages/Accounting/SalesOverview/SalesOverviewIndex'
import ExpensesOverviewIndex from '../pages/Accounting/ExpensesOverview/ExpensesOverviewIndex'
import InvestorOverviewIndex from '../pages/Accounting/InvestorOverview/InvestorOverviewIndex'
import ProjectPlotSummeryIndex from '../pages/Accounting/ProjectPlotSummery/ProjectPlotSummeryIndex'
import VendorList from '../pages/Vendor/VendorList'
import CompanyVendor from '../pages/Vendor/Company/CompanyVendor'
import AddVendorCompanyDetails from '../pages/Vendor/Company/AddVendorCompanyDetails'
import ViewProfileIndividualVendorCompany from '../pages/Vendor/Company/ViewProfileIndividualVendorCompany'
import ShowSingleIndividualVendorTransactions from '../pages/Vendor/Company/ShowSingleIndividualVendorTransactions'
import IndividualVendor from '../pages/Vendor/Individual/IndividualVendor'
import InsertIndividualVendor from '../pages/Vendor/Individual/InsertIndividualVendor'
import InvestorPortfolio from '../pages/Accounting/InvestorOverview/InvestorPortfolio'
import SendAmount from '../pages/Accounting/InvestorOverview/SendAmount'
import InvestorPortfolioOverview from '../pages/Accounting/InvestorOverview/InvestorPortfolioOverview'
import ProjectPDashboard from '../pages/Project Portfolio/ProjectPDashboard'
import AddPlotDetails from '../pages/Project Portfolio/AddPlotDetails'
import ServerDown from '../ServerDown'
import SecondLandInvestorShow from '../pages/AddLand/ShowLand/SecondLandInvestorShow'

export default function Routing() {
    return (
        <Routes>
            <Route path='*' element={<PageNotFound />} />
            {/* <Route path='/' element={<ServerDown />} /> */}
            <Route path='/' element={<Login />} />
            <Route path='/forgotpassword/' element={<ForgotPassowrd />} />
            <Route path='/resetpassword/' element={<ResetPassword />} />
            <Route path='/signup/' element={<SignUp />} />

            {/* All Pages */}
            <Route path='/allproject/' element={<Authentication Component={<MainLayout Component={<AllProject />} />} />} />
            <Route path='/addland/' element={<Authentication Component={<MainLayout Component={<LandLayout />} />} />} />
            <Route path='/projectdashboard/' element={<Authentication Component={<MainLayout Component={<ProjectDashboard />} />} />} />
            <Route path='/citymaster/' element={<Authentication Component={<MainLayout Component={<CityMaster />} />} />} />
            <Route path='/expensemaster/' element={<Authentication Component={<MainLayout Component={<ExpenseMaster />} />} />} />
            <Route path='/paymentmaster/' element={<Authentication Component={<MainLayout Component={<PaymentMaster />} />} />} />
            <Route path='/addexpenses/:id' element={<Authentication Component={<MainLayout Component={<ExpensesSummery />} />} />} />
            <Route path='/landowners/:id' element={<Authentication Component={<MainLayout Component={<SecondLandOwnerShow />} />} />} />
            <Route path='/landinvestor/:id' element={<Authentication Component={<MainLayout Component={<SecondLandInvestorShow />} />} />} />
            <Route path='/individualinvestor/' element={<Authentication Component={<MainLayout Component={<IndividualInvestor />} />} />} />
            <Route path='/individualvendor/' element={<Authentication Component={<MainLayout Component={<IndividualVendor />} />} />} />
            <Route path='/showinvestortransactions/:id' element={<Authentication Component={<MainLayout Component={<ShowSingleIndividualInvestorTransactions />} />} />} />
            <Route path='/showvendortransactions/:id' element={<Authentication Component={<MainLayout Component={<ShowSingleIndividualVendorTransactions />} />} />} />
            <Route path='/viewprofileinvestor/:id' element={<Authentication Component={<MainLayout Component={<ViewProfileIndividualInvestor />} />} />} />
            <Route path='/viewprofilevendor/:id' element={<Authentication Component={<MainLayout Component={<ViewProfileIndividualVendorCompany />} />} />} />
            <Route path='/saveindividualinvestor/:id' element={<Authentication Component={<MainLayout Component={<InsertIndividualInvestor />} />} />} />
            <Route path='/saveindividualivendor/:id' element={<Authentication Component={<MainLayout Component={<InsertIndividualVendor />} />} />} />
            <Route path='/addcompanyinvestor/:id' element={<Authentication Component={<MainLayout Component={<AddCompanyDetails />} />} />} />
            <Route path='/addcompanyvendor/:id' element={<Authentication Component={<MainLayout Component={<AddVendorCompanyDetails />} />} />} />
            <Route path='/showcompanyinvestor/' element={<Authentication Component={<MainLayout Component={<CompanyInvestor />} />} />} />
            <Route path='/showcompanyvendor/' element={<Authentication Component={<MainLayout Component={<CompanyVendor />} />} />} />
            <Route path='/showcompanytransactions/:id' element={<Authentication Component={<MainLayout Component={<ShowSingleIndividualCompanyTransactions />} />} />} />
            <Route path='/showvendorcompanytransactions/:id' element={<Authentication Component={<MainLayout Component={<ShowSingleIndividualVendorTransactions />} />} />} />
            <Route path='/viewprofilecompany/:id' element={<Authentication Component={<MainLayout Component={<ViewProfileIndividualCompany />} />} />} />
            <Route path='/viewprofilecompanyvendor/:id' element={<Authentication Component={<MainLayout Component={<ViewProfileIndividualVendorCompany />} />} />} />
            <Route path='/investorlist/' element={<Authentication Component={<MainLayout Component={<InvestorList />} />} />} />
            <Route path='/vendorlist/' element={<Authentication Component={<MainLayout Component={<VendorList />} />} />} />
            <Route path='/accountdashboard/' element={<Authentication Component={<MainLayout Component={<AccountDashboardIndex />} />} />} />
            <Route path='/accountdashboardadd/' element={<Authentication Component={<MainLayout Component={<AccountDashboardAddIndex />} />} />} />
            <Route path='/salesoverview/' element={<Authentication Component={<MainLayout Component={<SalesOverviewIndex />} />} />} />
            <Route path='/expensesoverview/' element={<Authentication Component={<MainLayout Component={<ExpensesOverviewIndex />} />} />} />
            <Route path='/investoroverview/' element={<Authentication Component={<MainLayout Component={<InvestorOverviewIndex />} />} />} />
            <Route path='/investorportfolio/' element={<Authentication Component={<MainLayout Component={<InvestorPortfolio />} />} />} />


            <Route path='/sendamount/' element={<Authentication Component={<MainLayout Component={<SendAmount />} />} />} />


            <Route path='/projectplotsummery/' element={<Authentication Component={<MainLayout Component={<ProjectPlotSummeryIndex />} />} />} />
            <Route path='/investorportfoliooverview/:investmentType/:payeeType/:referenceId/:paidName/:paidId/:transType' element={<Authentication Component={<MainLayout Component={<InvestorPortfolioOverview />} />} />} />
            <Route path='/projectportfolio/' element={<Authentication Component={<MainLayout Component={<ProjectPDashboard />} />} />} />
            <Route path='/addplotdeatils/:id' element={<Authentication Component={<MainLayout Component={<AddPlotDetails />} />} />} />


        </Routes>
    )
}
