export default async function GetIndividualInvestorDetails() {
  let url = window.RealestateService+  `/api/Investor/GetIndividualInvestorDetails`
  let head = {
       "content-type":"application/json",
      "accept":"*/*"
  }
  let response = await fetch(url, {
      method:"GET",
      headers:head,
  }).then(res=>res.json()).catch(res=>res)

  return response
}