import React, { useEffect, useState } from 'react'
import ProjectSummery from './LeftSide/ProjectSummery'
import ProjectDetails from './LeftSide/ProjectDetails'
import ProjectOver from './RightSide/ProjectOver'
import ProjectExpenses from './RightSide/ProjectExpenses'
import RecentTransaction from './RightSide/RecentTransaction'
import LandOwners from './RightSide/LandOwners'
import GetProject from '../../../APIs/Project/GetProjectById'
import InvestorDetails from './RightSide/InvestorDetails'

export default function ProjectDashboard() {
  let [landId, setLandId] = useState("")
  let [projectData, setProjectData] = useState([])
  let getData = async () => {
    let projectId = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
    let response = await GetProject(projectId)
    if (response.status === 1) {
      setProjectData(response.responseValue[0])
    }
    else {
      setProjectData([])
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='row pb-3'>
      <div className='col col-md-8'>
        <div className='d-flex flex-column gap-3'>
          <ProjectSummery projectData={projectData}/>
          <ProjectDetails setLandId={setLandId} projectData={projectData}/>
        </div>
      </div>
      <div className='col col-md-4'>
        <div className='d-flex flex-column gap-3' >
          <ProjectOver projectData={projectData}/>
          <ProjectExpenses projectData={projectData}/>
          {/* <RecentTransaction projectData={projectData}/> */}
          <InvestorDetails projectData={projectData} landId={landId}/>
          <LandOwners landId={landId} />
        </div>
      </div>
    </div>
  )
}
