import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import { Link, useParams } from 'react-router-dom'
import GetLandOwnerByLandId from '../../../APIs/Owner/GetLandOwnerByLandId'
import DeleteLandOwner from '../../../APIs/Owner/DeleteLandOwner'
import LandOwnerPopUp from './LandOwnerPopUp'

export default function SecondLandOwnerShow() {
    let id = useParams()
    let [ownerData, setOwnerData] = useState([])
    let [showModal, setShowModal] = useState(0)
    let [editData, setEditData] = useState([])
    let getData = async (id) => {
        let resp = await GetLandOwnerByLandId(id)
        if (resp.status === 1) {
            setOwnerData(resp.responseValue)
        }
    }
    let deleteLand = async (ids) => {
        let resp = await DeleteLandOwner(ids)
        if (resp.status === 1) {
            getData(id.id)

        }
        else {

        }
    }
    useEffect(() => {
        if (id.id !== "null") {
            console.log("idddd", id)
            getData(id.id)
        }
    }, [])
    return (
        <>
            <div className='container-fluid landformconatiner p-4 mt-3'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Land Owners" />
                    <FilterTable />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th>S.No.</th>
                                <th scope="col">Owner Name</th>
                                <th scope="col">Ownership %</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Pending Amount</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                ownerData && ownerData.map((val, ind) => {
                                    return (
                                        <tr >
                                            <td scope="row">{ind + 1}</td>
                                            <td>{val.name}</td>
                                            <td>{val.percentage}</td>
                                            <td>{val.amountToBePaid}</td>
                                            <td>{val.paidAmount}</td>
                                            <td>{val.pendingAmount}</td>
                                            <td>{val.paymentStatus}</td>
                                            <td>
                                                <div class="dropdown dropend">
                                                    {/* <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Dropdown button
                                                    </button> */}
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        <Link to={`/investorportfoliooverview/Project/${val.payeeType}/${val.referenceId}/${val.paidTo}/${val.paidToId}/1`}><li><span className="dropdown-item pointer" >View Transaction</span></li></Link>
                                                        <li><span className="dropdown-item pointer" onClick={()=>{setEditData(val); setShowModal(1)}}>Edit</span></li>
                                                        <li><span className="dropdown-item pointer" onClick={() => { deleteLand(val.id) }}>Delete</span></li>
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>

            {
                showModal === 1 ?
                    <LandOwnerPopUp showModal={showModal} closeModal={setShowModal} editData={editData} getData={()=>{getData(id.id)}}/>
                    :
                    ""
            }
        </>
    )
}
