import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import ProjectImage from "../../../../assets/images/landimg.png"
import AllKhasra from './AllKhasra'
import UpdateProject from '../../../../APIs/Project/UpdateProject'
import Loader from '../../../../components/Loader'
import GetProject from '../../../../APIs/Project/GetProject'
import GetLandDetailsByProjectId from '../../../../APIs/Land/GetLandDetailsByProjectId'
import { Link } from 'react-router-dom'
import SideMessage from '../../../../components/SideMessage'
export default function ProjectDetails({ setLandId, projectData }) {
    // let [projectData, setProjectData] = useState([])
    let [projectName, setProjectName] = useState("")
    let [landmark, setLandmark] = useState("")
    let [loader, setLoader] = useState(0)
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let handleShow = async () => {
        // console.log("sxbxhsxabkxbajkvxja", JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId === undefined)
        // let id = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId === undefined ? JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData[2] : JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
        // let projectData = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData !== undefined ? JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData : ["", "", 0]
        console.log("project Id", projectData)
        let projectId = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
        let resp = await GetLandDetailsByProjectId(projectId)
        if (resp.status === 1) {
            // console.log("all Land", resp.responseValue)
            window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": resp.responseValue, projectData: projectData, projectId: projectId }))
            // navigate("/projectdashboard/")
        }
        else {

        }
    }
    let getData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData
        console.log("data", data)
        // console.log("objectobjectobject", data)
        // setProjectData(data)
        setProjectName(data.projectName)
        setLandmark(data.landmark)
    }


    let handleSaveForm = async () => {
        setLoader(1)
        let sendForm =
        {
            "id": projectData.id,
            "projectNo": projectData.projectNo.slice(7, 10),
            "name": projectName,
            "landmark": landmark
        }

        let resp = await UpdateProject(sendForm)
        if (resp.status === 1) {
            // alert("Updated")

            let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData
            let allLand = JSON.parse(window.sessionStorage.getItem("ProjectData")).allLandData
            window.sessionStorage.setItem("ProjectData", JSON.stringify({
                "allLandData": allLand,


                // "projectData": [projectName, projectData.projectNo, projectData.totalLandPrice, projectData.totalLandArea, projectData.landmark, landmark] 

                projectData: {
                    projectName: projectName,
                    projectNo: projectData.projectNo,
                    projectId: projectData.id,
                    projectPrice: projectData.totalLandPrice,  // Corrected key assignment here
                    projectArea: projectData.totalLandArea,
                    landmark: projectData.landmark
                },
                projectId: projectData.id

            }))
            setSideToster({
                show: 1,
                message: "Project Details Updated!!",
                type: 'Success'
            })
            getData()
            setLoader(0)
        }
        else {
            setSideToster({
                show: 1,
                message: "Something Went Wrong!!",
                type: 'Alert'
            })
            setProjectName(projectData.projectData[0])
            setLoader(0)
        }
    }

    useEffect(() => {
        handleShow()
        setTimeout(() => {
            getData()
        }, 1000)
        console.log("xwsxdbjdsbcsbyuu")
    }, [])
    return (
        <>
            {
                projectData.length !== 0 ?
                    <>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "100%" }}>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename={projectData.projectName} />
                                <button className='editProject' data-bs-toggle="modal" data-bs-target="#addproject"><i class="fa-regular fa-pen-to-square fa-lg"></i>&nbsp; Edit Details</button>
                            </div>
                            <div className='row'>
                                <span className='text-secondary'>#{projectData.projectNo}</span>
                            </div>
                            <div className='row pt-3'>
                                <img src={ProjectImage} alt="Project" width="100%" height="100%" />
                            </div>
                            <div className='d-flex flex-row justify-content-between pt-3'>
                                <PageHeading pagename={`₹ ${projectData.totalLandPrice}`} />
                                <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                                    <i class="fa-solid fa-location-arrow locationIcon"></i>
                                    <Link to={`/addplotdeatils/${projectData.id}`}>
                                        <span className='locationtext'>Update Project Portfolio</span>
                                    </Link>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-3 pt-3'>
                                <div className='d-flex flex-row  align-items-center gap-3 '>
                                    <i class="fa-solid fa-up-right-and-down-left-from-center project-icon"></i>
                                    <span className='d-flex flex-column gap-1'>
                                        <label className='heading-project'>Total Land Area</label>
                                        <label className='heading-project' style={{ color: "#737791" }}>{projectData.totalLandArea} {projectData.unitName !== undefined ? projectData.unitName : "sq"}</label>
                                    </span>

                                </div>
                                <div className='d-flex flex-row  align-items-center gap-3'>
                                    <i class="bi bi-geo-alt-fill project-icon"></i>
                                    <span className='d-flex flex-column gap-1'>
                                        <label className='heading-project'>Location</label>
                                        <label className='heading-project' style={{ color: "#737791" }}>{projectData.landmark}</label>
                                    </span>
                                </div>
                            </div>

                            <AllKhasra setLandId={setLandId} />
                        </div>

                        {/* Modal  */}
                        <div className="modal  fade " id="addproject" tabindex="-1" aria-labelledby="addprojectLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "500px" }}>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5 text-primary" id="addprojectLabel">Update Project</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setProjectName(projectData.projectName) }}></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Project Name</label>
                                                    <input type='text' className='form-input-box' placeholder='Enter Project Name' value={projectName} name="name" onChange={(e) => { setProjectName(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Landmark</label>
                                                    <input type='text' className='form-input-box' placeholder='Enter Landmark' value={landmark} name="landmark" onChange={(e) => { setLandmark(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row '>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Unique Id</label>
                                                    <input type='text' className='form-input-box' value={"#" + projectData.projectNo} disabled />
                                                </div>
                                            </div>
                                            {/* <span className='pt-1 fw-bolder'><p style={{ fontSize: "0.66rem", color: "#FE996C" }}>Unique ID generated automatically</p></span> */}
                                        </div>
                                    </div>
                                    <div className="modal-footer" >
                                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                        <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>



                    : ""
            }


            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
            <Loader show={loader} />


        </>
    )
}

