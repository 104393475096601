import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FileUpload from '../../../components/FileUpload'
import InsertPaymentDetails from '../../../APIs/PaymentDetails/InsertPaymentDetails'
import Loader from '../../../components/Loader'
import SideMessage from '../../../components/SideMessage'
import GetPaymentMaster from '../../../APIs/Master/Payment/GetPaymentMaster'

const SendAmount = ({ showModal, setShowModal, payeeType, investorName, investorId, referenceId, pendingAmount, transactionType, investmentType }) => {
  let [deletedImages, setDeletedImages] = useState([])
  let [allImage, setAllImage] = useState([])
  let [loader, setLoader] = useState(0)
  let [paymentList, setPaymentList] = useState([])

  const [sendForm, setSendForm] = useState({
    PayeeType: payeeType,
    referenceId: referenceId,
    paidTo: investorId,
    amount: 0,
    paymentTypeId: '-1',
    paymentDate: 0,
    remark: '',
    TransactionType: 2,
    createdBy: window.userId,
    TransactionId: '',
    TransactionId: '',
    investmentType: investmentType,
    // paymentDetailFiles: []
  })
  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  let clear = () => {
    setSendForm({
      PayeeType: 0,
      referenceId: 0,
      TransactionType: 0,
      paidTo: 0,
      amount: 0,
      paymentTypeId: '-1',
      paymentDate: 0,
      remark: '',
      TransactionType: 2,
      TransactionId: '',
      TransactionType: '',
      investmentType: ""
    })
  }
  const handleSave = async () => {
    if (sendForm.amount !== 0 && sendForm.amount !== null && sendForm.TransactionId !== '' && sendForm.paymentTypeId !== '-1' && sendForm.paymentDate !== 0, sendForm.remark !== "") {


      const formData = new FormData();
      for (const key in sendForm) {
        if (sendForm.hasOwnProperty(key)) {
          formData.append(key, sendForm[key]);
        }
      }

      formData.append('paymentDetailFiles', allImage[0]); // Ensure this is a valid file object

      // formData.append("paymentDetailFiles", allImage)
      const response = await InsertPaymentDetails(formData);
      setLoader(1)
      if (response.status === 1) {
        setLoader(0)
        setSideToster({
          show: 1,
          message: 'Data Saved',
          type: 'Success'
        })
        clear()

        window.location.reload();
      }
      else {
        setLoader(0)
        setSideToster({
          show: 1,
          message: response.responseValue[0].message,
          type: 'Alert'
        })
      }
    }
    else{
      console.log("call")

      setSideToster({
        show: 1,
        message: "Please Fill All Fields!!",
        type: 'Alert'
      })
    }

  }
  let getData = async () => {
    let payment = await GetPaymentMaster()

    if (payment.status === 1) {
      setPaymentList(payment.responseValue)
    }

  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div class={`modal d-${showModal === 1 ? "block" : "none"}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <PageHeading pagename="Send Amount" />
              <button type="button" class="btn-close" onClick={() => { setShowModal(0) }}></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="row container-fluid landformconatinermaster_ p-4">
                  <div className="col col-md-4 pb-2_ pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Send To</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={investorName && investorName}
                        disabled
                        onChange={handleChange}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Pending Amount</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        // placeholder="Enter expense name"
                        disabled
                        onChange={handleChange}
                        value={pendingAmount}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Transaction Type</label>
                      <select className='form-input-box form-select pe-5' name='paymentTypeId' onChange={handleChange} value={sendForm.paymentTypeId}>
                        <option value="-1">--Select Payment Type--</option>
                        {
                          paymentList && paymentList.map((val, ind) => {
                            return (
                              <option value={val.id}>
                                {val.name}
                              </option>
                            )
                          })
                        }
                      </select>
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Transaction Id</label>
                      <input
                        type="text"
                        name="TransactionId"
                        id="TransactionId"
                        placeholder="Enter Transaction Id"
                        onChange={handleChange}
                        value={sendForm.TransactionId}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        name="paymentDate"
                        id="paymentDate"
                        placeholder="Enter expense name"
                        onChange={handleChange}
                        value={sendForm.paymentDate}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Amount</label>
                      <input
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                        value={sendForm.amount}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Remark</label>
                      <input
                        type="text"
                        name="remark"
                        id="remark"
                        placeholder="Enter Remark"
                        onChange={handleChange}
                        value={sendForm.remark}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>

                </div>

                <div className='row'>
                  <div className="col ol-md-12  pb-md-0">
                    <FileUpload setDeletedImages={setDeletedImages} deletedImages={deletedImages} setAllImage={setAllImage} allImage={allImage} name="Add Attachments" />
                    <sapn className="text-danger">Before clicking send button please review all the details very care fully</sapn><br />
                    <span class="btn btn-primary mt-2" onClick={handleSave}>Confirm and Send</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {
        loader === 1 ? <Loader show={loader} /> : ""
      }

      {sideToster.show ===1?
        <SideMessage type={sideToster.type} message={sideToster.message} show={sideToster.show} close={setSideToster}/>:""
      }

    </>
  )
}

export default SendAmount